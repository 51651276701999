@charset "UTF-8";

// Article
// ------------------------------------------------------------------
.Article {
	@at-root {

		& {

			@include mq(sp) {
				padding: 15px;
				background: rgba(255, 255, 255, 0.2);
			}

		}

		.Article_Heading {
			font-size: 24px;
			font-weight: 600;

			@include mq(pc) {
				font-size: 30px;
			}
		}

		.Article_Info {
			margin-top: 10px;

			@include mq(pc) {
				width: 50%;
				float: left;
				margin-top: 30px;
			}

			@include mq(sp) {
				display: inline-block;
			}
		}

		.Article_Date {
			display: inline-block;
			color: $mt-color3;
			font-size: 12px;
			font-family: 'Montserrat', sans-serif;

			&::after {
				content: "";
				display: inline-block;
				width: 1px;
				height: 10px;
				margin: 0 5px;
				background: $mt-color3;

				@include mq(pc) {
					margin: 0 10px;
				}
			}
		}

		.Article_Category {
			display: inline-block;

			a {
				color: $mt-color3;
				font-size: 12px;
				text-decoration: none;
			}
		}

		.Article_Share {
			list-style: none;
			margin-top: 10px;

			@include mq(pc) {
				display: inline-block;
				float: right;
				margin-top: 30px;
			}

			@include mq(sp) {
				display: inline-block;
				float: right;
			}

			li {
				display: inline-block;

				iframe {
					vertical-align: bottom;
					margin-right: 10px;
				}
			}
		}

		.Article_Contents {
			clear: both;
			padding-top: 30px;

			@include mq(pc) {
				margin-top: 60px;
			}

			p,li {
				line-height: 1.7;
			}

			*:first-child {
				margin-top: 0;
			}

			h3 {
				font-size: 18px;
				margin-top: 50px;

				@include mq(pc) {
					font-size: 22px;
				}
			}

			h4 {
				font-size: 16px;
				margin-top: 30px;

				@include mq(pc) {
					font-size: 20px;
				}
			}

			p {

				&:not(:first-child) {
					margin-top: 30px;
				}

				@include mq(sp) {
					font-size: 14px;
				}
			}

			ul {
				margin-left: 5px;
				list-style: none;

				&:not(:first-child) {
					margin-top: 30px;
				}

				li {
					text-indent: -12px;
					margin-left: 12px;

					&::before {
						content: "";
						display: inline-block;
						border-radius: 4px;
						width: 4px;
						height: 4px;
						background: $mt-color1;
						position: relative;
						top: -4px;
						margin-right: 8px;
					}
				}
			}

			ol {
				margin-left: 20px;

				&:not(:first-child) {
					margin-top: 30px;
				}
			}

			li {

				@include mq(sp) {
					font-size: 14px;
				}
			}

			blockquote {
				background: #efefef;
				padding: 30px;

				&:not(:first-child) {
					margin-top: 30px;
				}

				p {

					&:not(:first-child) {
						margin-top: 15px;
					}

					@include mq(sp) {
						font-size: 14px;
					}
				}

				cite {
					display: block;
					font-size: 12px;
					margin-top: 20px;
				}
			}

			a {
				transition: all 0.3s ease-in;

				&:hover {

					@include mq(pc) {
						opacity: 0.6;
					}
				}
			}
		}

		.Article_Img {

			@include mq(pc) {
				max-width: 680px;
				max-height: 680px;
			}

			&:not(:first-child) {
				margin-top: 30px;
			}

			img {
				max-width: 100%;

				@include mq(pc) {
					max-height: 680px;
				}
			}

			figcaption {
				font-size: 12px;
				color: $mt-color3;
				margin-top: 10px;

				a {
					color: $mt-color3;
				}
			}
		}
	}
}
