@charset "UTF-8";

// Main
// ------------------------------------------------------------------
.Main {
	@at-root {

		& {

			@include mq(pc) {
				width: 680px;
				margin-top: 55px;
			}
		}

		// ワンカラム
		.Page_Contents.-one {

			.Main {
				width: 100%;
				padding: 0 15px;

				@include mq(pc) {
					padding: 0 100px;
				}
			}
		}

		.Special + .Main {

			@include mq(sp) {
				margin-top: 20px;
			}
		}
	}
}
