@charset "UTF-8";

// Talked
// 記事詳細ページ内の、「お話を伺った人」のパーツ ------------------------------------------------------------------
.Talked {
	@at-root {

		& {
			border: 8px solid rgba(147,131,131,0.12);
			padding: 20px;
			margin-top: 60px;

			@include mq(pc) {
				margin-top: 60px;
				display: flex;
			}
		}

		.Talked_Img {
			width: 200px;

			@include mq(pc) {
				width: 200px;
			}

			@include mq(sp) {
				margin: 0 auto;
			}

			img {
				max-width: 100%;
				border: 2px solid rgba(147,131,131,0.12);

				@include mq(pc) {
					width: 300px;
				}
			}
		}

		.Talked_PhotoSet {
			margin-top: 20px;

			@include mq(pc) {
				display: flex;
				margin-top: 15px;
			}
		}

		.Talked_PhotoItem {

			@include mq(pc) {
				width: 200px;
			}

			@include mq(sp) {
				margin-top: 10px;
			}

			+ .Talked_PhotoItem {

				@include mq(pc) {
					margin-left: 10px;
				}
			}

			img {
				width: 100%;
			}
		}

		.Talked_Tx {

			@include mq(pc) {
				padding: 0 0 0 20px;
			}

			@include mq(sp) {
				margin-top: 15px;
			}

			dt {
				font-size: 12px;
				color: $mt-color3;
			}

			dd {

			}

			p:not(:first-child) {
				font-size: 14px;
				margin-top: 15px;
			}
		}
	}
}
