@charset "UTF-8";

// Relation
// ------------------------------------------------------------------
.Relation {
	@at-root {

		& {
			margin-top: 60px;

			.EntryList_Img {

				@include mq(pc) {
					width: 160px;
					height: 140px;
				}

				span {

					@include mq(pc) {
						width: 140px;
						height: 140px;
					}
				}
			}

			.EntryList_Detail {

				@include mq(pc) {
					width: 520px;
				}
			}
		}

		.Relation_Heaading {
			padding: 0 15px 10px 30px;
			position: relative;
			line-height: 1;
			font-size: 17px;
			font-family: 'Montserrat', sans-serif;
			font-weight: 300;

			@include mq(sp) {
				// border-bottom: 1px dotted $mt-color1;
			}

			@include mq(pc) {
				padding: 0 0 0 20px;
				font-size: 18px;
			}

			&::before {
				content: "";
				display: inline-block;
				background: $mt-color1;
				width: 2px;
				height: 15px;
				position: absolute;

				@include mq(sp) {
					top: 0;
					left: 15px;
				}

				@include mq(pc) {
					bottom: 2px;
					left: 0;
				}
			}

			&::after {
				content: "";
				display: inline-block;
				background: $mt-color1;
				width: 2px;
				height: 15px;
				position: absolute;

				@include mq(sp) {
					top: 0;
					left: 19px;
				}

				@include mq(pc) {
					bottom: 2px;
					left: 5px;
				}
			}
		}
	}
}
