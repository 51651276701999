@charset "UTF-8";

@import url('//fonts.googleapis.com/earlyaccess/notosansjp.css');
// font-family: 'Noto Sans JP', sans-serif;

@import url('//fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900');

// font-family: 'Montserrat', sans-serif;

html {
  font-weight: 400; // medium
  font-family: 'Noto Sans JP', sans-serif;
	line-height: 1.5;
  width: 100%;
  height: 100%;
  word-wrap: break-word;
  word-break: break-all;
	color: $mt-color1;
}

img {
  vertical-align: top;
}

a {
	color: $mt-color1;
}
