@charset "UTF-8";

// List
// ------------------------------------------------------------------
.List {
	@at-root {

		& {
			list-style: none;

			&.-normal {
				margin-left: 5px;
				list-style: none;

				&:not(:first-child) {
					margin-top: 30px;
				}

				li {
					text-indent: -12px;
					margin-left: 12px;

					&::before {
						content: "";
						display: inline-block;
						border-radius: 4px;
						width: 4px;
						height: 4px;
						background: $mt-color1;
						position: relative;
						top: -4px;
						margin-right: 8px;
					}
				}
			}

			&.-number {
				margin-left: 20px;

				&:not(:first-child) {
					margin-top: 30px;
				}

				li {
					list-style: decimal;
				}
			}

			li {
				margin-top: 5px;

				@include mq(sp) {
					font-size: 14px;
				}
			}
		}
  }
}
