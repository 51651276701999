@charset "UTF-8";

// Pager
// ------------------------------------------------------------------
.Pager {
	@at-root {

		& {
			text-align: center;
			margin-top: 40px;

			@include mq(pc) {
				margin-top: 50px;
			}

			li {
				display: inline-block;
				margin: 0 1px;

				@include mq(pc) {
					margin: 0 5px;
				}
			}

			a {
				display: block;
				background: $mt-color1;
				color: #fff;
				text-decoration: none;
				width: 30px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				font-size: 12px;

				&:hover {
					opacity: 0.6;
				}
			}

			span {
				display: block;
				width: 30px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				border: 1px solid $mt-color1;
				font-size: 12px;
			}

			img {
				display: inline-block;
				height: 10px;
				margin-top: 10px;
			}

			.Pager_Arrow {
				background: none;
			}

			.Pager_Dot {
				border: none;
			}

		}
  }
}
