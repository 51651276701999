@charset "UTF-8";

// TextBox
// ------------------------------------------------------------------
.TextBox {
	@at-root {

		& {

			&:not(:first-child) {
				margin-top: 15px;

				@include mq(pc) {
					margin-top: 30px;
				}
			}

			p {
				font-size: 14px;

				@include mq(pc) {
					font-size: 16px;
				}

				&:not(:first-of-type) {
					margin-top: 20px;
				}
			}
		}

		.TextBox_Img {

			@include mq(pc) {
				width: 300px;
				margin-left: 20px;
			}

			@include mq(sp) {
				width: 260px;
				display: block;
				margin: 0 auto 20px;
			}

			&.-right {

				@include mq(pc) {
					float: right;
				}
			}
		}
  }
}
