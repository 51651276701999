@charset "UTF-8";

// Privacy
// ------------------------------------------------------------------
.Privacy {
	@at-root {

		& {
			margin-top: 100px;

			li {
				margin-top: 15px;
				font-size: 12px;

				@include mq(pc) {
					font-size: 14px;
				}
			}

			.TextBox p {
				font-size: 12px;

				@include mq(pc) {
					font-size: 14px;
				}
			}
		}

		.Privacy_Heading {
			font-weight: 400;
			font-size: 20px;

			@include mq(pc) {
				font-size: 24px;
			}
		}
	}
}
