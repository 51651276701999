@charset "UTF-8";

// EntryList
// ------------------------------------------------------------------
.EntryList {
	@at-root {

		& {

		}

		.EntryList_Heading {
			padding: 0 15px 10px 30px;
			position: relative;
			line-height: 1;

			@include mq(sp) {
				// border-bottom: 1px dotted $mt-color1;
			}

			@include mq(pc) {
				padding: 0 0 0 20px;
			}

			&::before {
				content: "";
				display: inline-block;
				background: $mt-color1;
				width: 2px;
				height: 15px;
				position: absolute;

				@include mq(sp) {
					top: 8px;
					left: 15px;
				}

				@include mq(pc) {
					bottom: 2px;
					left: 0;
				}
			}

			&::after {
				content: "";
				display: inline-block;
				background: $mt-color1;
				width: 2px;
				height: 15px;
				position: absolute;

				@include mq(sp) {
					top: 8px;
					left: 19px;
				}

				@include mq(pc) {
					bottom: 2px;
					left: 5px;
				}
			}

			.-en {
				font-size: 17px;
				font-family: 'Montserrat', sans-serif;
				font-weight: 300;

				@include mq(pc) {
					font-size: 20px;
				}
			}

			.-ja {
				font-size: 10px;
				font-weight: 300;

				&::before {
					content: "-";
					margin-right: 5px;
					margin-left: 10px;
				}

				&::after {
					content: "-";
					margin-left: 5px;
				}
			}

			&.-category {
				font-weight: 400;

				.-categoryName {
					font-size: 18px;

					@include mq(pc) {
						font-size: 24px;
					}
				}
				.-categorySub {
					font-size: 14px;

					@include mq(pc) {
						font-size: 16px;
					}
				}
			}
		}

		.EntryList_Item {
			border-top: 1px dotted $mt-color2;

			@include mq(sp) {
				background: rgba(255, 255, 255, 0.2);
			}

			&:first-of-type {

				@include mq(pc) {
					margin-top: 15px;
				}
			}

			a {

				color: $mt-color1;
				text-decoration: none;
				padding: 15px;

				@include mq(pc) {
					display: flex;
					flex-direction: row-reverse;
					padding: 20px 0;
					transition: all 0.3s ease-in;
				}

				@include mq(sp) {
					position: relative;
					display: block;
					min-height: 118px;
				}

				&:hover {

					@include mq(pc) {
						opacity: 0.6;
					}
				}
			}

			&.-new {

				a {

					@include mq(sp) {
						position: relative;

						&::after {

							@include mq(sp) {
								content: "NEW";
								background: $mt-color4;
								display: inline-block;
								color: #fff;
								font-family: 'Montserrat', sans-serif;
								font-weight: 500;
								padding: 0 10px;
								line-height: 15px;
								height: 15px;
								letter-spacing: 0.1em;
								position: absolute;
								font-size: 10px;
								top: 15px;
								left: 15px;
							}
						}
					}
				}

				.EntryList_Heading02 {

					&::after {

						@include mq(pc) {
							content: "NEW";
							background: $mt-color4;
							display: inline-block;
							color: #fff;
							font-family: 'Montserrat', sans-serif;
							font-weight: 700;
							padding: 0 12px;
							margin-left: 10px;
							line-height: 15px;
							height: 15px;
							letter-spacing: 0.1em;
							position: relative;
							font-size: 10px;
							top: -4px;
						}
					}
				}
			}
		}

		.EntryList_Img {

			@include mq(pc) {
				width: 200px;
				height: 180px;
			}

			@include mq(sp) {
				position: absolute;
				top: 15px;
				left: 15px;
				width: 85px;
				height: 85px;
			}


			span {
				display: block;
				width: 85px;
				height: 85px;

				@include mq(pc) {
					width: 180px;
					height: 180px;
				}
			}
		}

		.EntryList_Detail {

			@include mq(sp) {
				padding: 0 15px 0 95px;
			}

			@include mq(pc) {
				width: 480px;
			}
		}

		.EntryList_Heading02 {
			font-size: 16px;
			font-weight: 400;

			@include mq(sp) {
				line-height: 1.35;
			}

			@include mq(pc) {
				font-size: 23px;
				font-weight: 400;
			}
		}

		.EntryList_Info {
			text-align: right;

			@include mq(pc) {
				margin-top: 5px;
			}
		}

		.EntryList_Category {
			display: inline-block;
			color: $mt-color3;
			font-size: 12px;
		}

		.EntryList_Date {
			display: inline-block;
			color: $mt-color3;
			font-size: 12px;
			font-family: 'Montserrat', sans-serif;
			letter-spacing: 0.1em;

			&::after {
				content: "";
				display: inline-block;
				width: 1px;
				height: 10px;
				margin: 0 10px;
				background: $mt-color3;
			}
		}

		.EntryList_Tx {
			font-size: 12px;
			color: $mt-color3;
			margin-top: 10px;

			@include mq(sp) {
				display: none;
			}
		}
  }
}
