@charset "UTF-8";

// Contact
// ------------------------------------------------------------------
.Contact {
	@at-root {

		& {
			margin-top: 30px;

			.TextBox {

				p {

					@include mq(pc) {
						text-align: center;
					}

					&:last-of-type {
						margin-top: 30px;

						@include mq(pc) {
							margin-top: 50px;
						}
					}
				}
			}
		}
	}
}
