@charset "UTF-8";

// PageHeader
// ------------------------------------------------------------------
.PageHeader {
	@at-root {

		& {
			width: 100%;
			margin: 0 auto;

			@include mq(pc) {
				width: 1024px;
			}
		}

		.PageHeader_Logo {
			// text-align: center;
			padding: 37px 0 14px 15px;

			@include mq(pc) {
				display: inline-block;
				padding: 90px 0 30px;
				width: 300px;
			}

			@include mq(sp) {
				line-height: 1;
			}

			a {
				transition: all 0.3s ease-in;

				&:hover {

					@include mq(pc) {
						opacity: 0.6;
					}
				}
			}

			img {

				@include mq(pc) {
					width: 300px;
				}

				@include mq(sp) {
					width: 200px;
				}
			}
		}

		.PageHeader_Menu {
			position: absolute;
			top: 32px;
			right: 10px;
			background: rgba(147, 131, 131, 0.2);
			width: 40px;
			height: 40px;
			padding-top: 15px;

			span {
				display: block;
				background: $mt-color1;
				width: 15px;
				height: 1px;
				margin: 0 auto;

				&:not(:first-child) {
					margin-top: 4px;
				}
			}

			@include mq(pc) {
				display: none;
			}
		}

		.PageHeader_Tx {


			@include mq(pc) {
				display: inline-block;
				font-size: 12px;
				color: $mt-color1;
				margin-left: 20px;
			}

			@include mq(sp) {
				background: $mt-color1;
				color: #fff;
				font-size: 10px;
				height: 22px;
				line-height: 22px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				padding: 0 0 0 15px;
			}
		}

		.-fixed {
			.PageHeader {

				@include mq(sp) {
					position: fixed;
					z-index: 10;
					background: rgba(255, 255, 255, 0.8);
					border-bottom: 1px solid rgba($mt-color3, 0.2)
				}
			}
			.PageHeader_Tx {

				@include mq(sp) {
					display: none;
				}
			}

			.PageHeader_Logo {

				@include mq(sp) {
					padding: 14px 10px;
				}
			}

			.PageHeader_Menu {

				@include mq(sp) {
					top: 10px;
				}
			}
		}
	}
}
