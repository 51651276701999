@charset "UTF-8";

// Special
// ------------------------------------------------------------------
.Special {
	@at-root {

		& {
			width: 100%;

			@include mq(pc) {
				display: flex;
				// margin-top: 55px;
			}
		}

		.Special_Item {

			@include mq(pc) {
				width: 50%;
			}

			&:not(:first-of-type) {

				@include mq(sp) {
					margin-top: 2px;
				}
			}

			a {
				color: $mt-color1;
				display: block;
				text-decoration: none;
				position: relative;

				@include mq(pc) {
					height: 340px;
					overflow: hidden;
					transition: all 1s ease-in;
				}

				img {

					@include mq(pc) {
						position: relative;
						transition: all 0.3s ease-in;
						z-index: 1;
					}
				}

				&::after {
					content: "";
					display: block;
					border: 8px solid rgba(238, 214, 235, 0.6);
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 4;
					transition: all 0.3s ease-in;
				}

				&:hover {

					@include mq(pc) {

						img {
							transform: scale(1.06);
						}

						&::after {
							border: 9px solid rgba(71, 59, 70, 0.4);
						}
					}
				}
			}
		}

		.Special_Tx {
			background: rgba(255, 255, 255, 0.6);
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 14px 17px;

			@include mq(pc) {
				display: flex;
				flex-direction: column-reverse;
				padding: 14px 25px;
				height: 124px;
				z-index: 2;
			}
		}

		.Special_Heading {
			font-size: 18px;
			font-weight: 400;
			line-height: 1.4;

			@include mq(pc) {
				font-size: 23px;
				margin-top: 5px;
				height: 2.8em;
			}
		}

		.Special_Category {
			position: absolute;
			top: 6px;
			left: 8px;

			@include mq(pc) {
				top: 229px;
				left: 23px;
				z-index: 3;
			}

			span {
				background: rgba(74, 69, 69, 0.8);
				color: #ffffff;
				display: inline-block;
				font-size: 12px;
				height: 25px;
				letter-spacing: 0.05em;
				line-height: 25px;
				padding: 0 10px;
			}
		}

		.Special_Img {

			img {
				width: 100%;

				@include mq(pc) {
					width: 510px;
				}
			}
		}
	}
}
