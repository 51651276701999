@charset "UTF-8";

// Page
// ------------------------------------------------------------------
.Page {
	@at-root {

		& {
			background: url("/assets/images/bg_flower.jpg") no-repeat right top, url("/assets/images/bg_gradient.jpg") repeat-x left top;
			background-size: 862px auto, 1px auto;
			width: 100%;
			position: relative;

			@include mq(pc) {
				min-width: 1024px;
				overflow-x: hidden;
				display: flex;
				flex-wrap: wrap;
			}

			@include mq(sp) {
				height: 100%;
				background: #ffffff url("/assets/images/bg_flower.jpg") no-repeat right top;
				background-size: contain;
			}

		}

		.Page_Header {
			width: 100%;
		}

		.Page_Contents {
			width: 100%;
			margin-bottom: 60px;

			@include mq(pc) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 1024px;
				margin: 0 auto 100px;
			}

			&.-one {
				display: block;
				background: rgba(238, 214, 235, 0.2);

				@include mq(pc) {
					padding-bottom: 100px;
				}

				@include mq(sp) {
					background: rgba(255, 255, 255, 0.2);
					padding-top: 30px;
				}

				p {
					line-height: 1.7;
				}
			}
		}

		.Page_Footer {
			width: 100%;
			background: #473b46;

		  @include mq(pc) {

      }
		}
	}
}
