@charset "UTF-8";

// Category
// ------------------------------------------------------------------
.Category {
	@at-root {

		& {
			background: rgba(255, 255, 255, 0.2);
			padding: 15px 0 0;
		}
	}
}
