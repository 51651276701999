@charset "UTF-8";

// Agreement
// ------------------------------------------------------------------
.Agreement {
	@at-root {

		& {

			.List li {
				font-size: 12px;

				@include mq(pc) {
					font-size: 14px;
				}
			}

			.TextBox p {
				font-size: 12px;

				@include mq(pc) {
					font-size: 14px;
				}
			}
		}
	}
}
