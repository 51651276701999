@charset "UTF-8";

// Heading
// ------------------------------------------------------------------
.Heading {
	@at-root {

		& {
			clear: both;

			&.-hd1 {
				font-size: 20px;
				font-weight: 400;

				@include mq(pc) {
					font-size: 26px;
					text-align: center;
					margin-bottom: 40px;
				}

				+.-hd2 {

					@include mq(sp) {
						margin-top: 30px;
					}
				}
			}

			&.-hd2 {
				font-size: 18px;
				margin-top: 50px;

				@include mq(pc) {
					font-size: 20px;
					margin-top: 60px;
				}
			}

			&.-hd3 {
				font-size: 16px;
				margin-top: 30px;

				@include mq(pc) {
					font-size: 18px;
				}
			}
		}
	}
}
