
// ArticleWriterWriter
// ------------------------------------------------------------------
.ArticleWriter {
	@at-root {

		& {
			border: 1px solid $mt-color3;
			margin-top: 20px;
			padding: 20px;

			@include mq(pc) {
				padding: 30px;
				margin-top: 30px;
			}
		}

		.ArticleWriter_Name {
			font-size: 14px;

			span {
				font-weight: bold;
			}
		}

		.ArticleWriter_Tx {
			font-size: 12px !important;
			margin-top: 10px !important;
		}
	}
}
