@charset "UTF-8";

// Nav
// ------------------------------------------------------------------
.Nav {
	@at-root {

		& {
			width: 100%;

			@include mq(pc) {
				position: fixed;
				z-index: 10;
				height: 54px;
				background: $mt-color1;
				// position: absolute;
				top: 0;
				left: 0;
			}

			@include mq(sp) {
				// display: none;
				opacity: 0;
				visibility: hidden;
				position: fixed;
				top: 0;
				bottom: 0;
				height: 100%;
				background: rgba(74, 69, 69, 0.9);
				z-index: 1000;
				transition: all 0.3s ease-in;
			}

			&.-opened {

				@include mq(sp) {
					opacity: 1;
					visibility: visible;
					transition: all 0.3s ease-in;
				}
			}

			ul {
				list-style: none;

				@include mq(pc) {
					display: flex;
					// justify-content: center;
					width: 1024px;
					margin: 0 auto;
				}

				@include mq(sp) {
					margin-top: 65px;
				}
			}

			li {

				@include mq(pc) {
					display: inline-block;
				}
			}

			.Nav_logo {

				@include mq(pc) {
					display: none;
				}

				@include mq(sp) {
					display: none;
				}
			}

			a {
				color: #fff;
				display: block;
				font-size: 14px;
				text-decoration: none;

				@include mq(pc) {
					line-height: 54px;
					padding: 0 20px;
					transition: all 0.5s linear;
					font-size: 16px;
				}

				@include mq(sp) {
					padding: 15px 23px;
				}

				&:hover {

					@include mq(pc) {
						background: rgba(147, 131, 131, 0.3);
					}
				}
			}

			.Nav_BtnClose {

				@include mq(pc) {
					display: none;
				}

				@include mq(sp) {
					text-indent: -9999px;
					position: absolute;
					top: 0;
					right: 0;
					width: 60px;
					height: 60px;

					&::before {
						content: "";
						display: block;
						width: 25px;
						height: 2px;
						transform: rotate(-45deg);
						background: #fff;
						position: absolute;
						top: 27px;
						right: 15px;
					}

					&::after {
						content: "";
						display: block;
						width: 25px;
						height: 2px;
						transform: rotate(45deg);
						background: #fff;
						position: absolute;
						top: 27px;
						right: 15px;
					}
				}
			}
		}



		.-fixed {
			.Nav {

				@include mq(pc) {
					display: flex;
				}
			}

			.Nav_logo {

				@include mq(pc) {
					display: block !important;
					background: #fff;

					img {
						padding-top: 12px;
						width: 180px;
					}
				}
			}
		}
	}
}
