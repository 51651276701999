@charset "UTF-8";

// Page
// ------------------------------------------------------------------
.Pagetop {
	@at-root {

		& {

			a {
				display: block;
				text-indent: -9999px;
				background: $mt-color2;
				height: 35px;
				position: relative;
				transition: all 0.3s ease-in;

				&::before {
					content: "";
					display: block;
					border-top: 2px solid $mt-color1;
					border-right: 2px solid $mt-color1;
					width: 10px;
					height: 10px;
					position: absolute;
					left: 50%;
					margin-left: -5px;
					top: 15px;
					transform: rotate(-45deg);
				}

				&:hover {

					@include mq(pc) {
						opacity: 0.6;
					}
				}
			}
		}
	}
}
