@charset "UTF-8";

// 便利クラス
// ------------------------------------------------------------------
.-textRight {
	text-align: right !important;
}

.-size10 {
	font-size: 10px !important;
}
.-size12 {
	font-size: 12px !important;
}
