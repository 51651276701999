@charset "UTF-8";

// Aside
// ------------------------------------------------------------------
.Aside {
	@at-root {

		& {
			margin: 60px 15px 0;

			@include mq(pc) {
				width: 300px;
				margin-top: 55px;
			}
		}

		.Aside_Heading {
			font-weight: 300;
			font-size: 16px;

			&::before {
				content: "";
				display: inline-block;
				width: 2px;
				height: 15px;
				margin-right: 10px;
				background: $mt-color1;
			}

			.-en {
				font-family: 'Montserrat', sans-serif;
			}
		}

		.Aside_Img {
			margin-top: 15px;
			text-align: center;

			img {
				width: 260px;
				border: 1px solid rgba(147, 131, 131, 0.12);
			}
		}

		.Aside_Tx {
			color: $mt-color1;
			font-size: 14px;
			margin-top: 15px;

			p {

				&:not(:first-of-type) {
					margin-top: 15px;
				}
			}
		}

		.Aside_Link {
			text-align: right;
			margin-top: 15px;

			a {
				color: $mt-color1;
				font-size: 14px;
				transition: all 0.3s ease-in;

				&::before {
					content: "";
					background: url("/assets/images/icon_arrow_001.svg") no-repeat;
					background-size: contain;
					display: inline-block;
					width: 8px;
					height: 7px;
					margin-right: 10px;
				}

				&:hover {

					@include mq(pc) {
						opacity: 0.6;
					}
				}
			}
		}

		.Aside_Banner {
			width: 100%;
			margin-top: 30px;

			@include mq(pc) {
				width: 300px;
				margin-top: 30px;
			}

			&:first-of-type {

				@include mq(sp) {
					margin-top: 60px;
				}
			}

			img {
				width: 100%;
			}
		}
	}
}
