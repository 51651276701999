@charset "UTF-8";

// Colors
// ------------------------------------------------------------------
$mt-color1: #4a4545; //文字色、リンク職
$mt-color2: #e4e4e4; //ほぼ白に近いグレー
$mt-color3: #938383; // くすんだベージュ 147, 131, 131
$mt-color4: #f1a1c6; //pink



// Breakpoints
// ------------------------------------------------------------------
$breakpoints: (
	// iPad mini 以上のサイズはPC版が表示されます
	'sp': 'screen and (max-width: 767px)',
	'pc': 'all and (min-width: 768px)',
) !default;

@mixin mq($breakpoint: pc) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}


// Functions
// ------------------------------------------------------------------

// テキストコンテンツ周辺の空白を、line-heightを計算に入れて算出する。
// 隣接するコンテンツがテキストである場合。
@function lineGap($baseHeight, $fontSize, $lineHeight) {
	@if unit($lineHeight) == "" {
		@return $baseHeight - ($fontSize * $lineHeight - $fontSize);
	} @else {
	@return $baseHeight - ($lineHeight - $fontSize);
	}
}

// テキストコンテンツ周辺の空白を、line-heightを計算に入れて算出する。
// 半分サイズ。隣接するコンテンツがテキストではない場合。
@function lineGapHalf($baseHeight, $fontSize, $lineHeight) {
	@if unit($lineHeight) == "" {
		@return $baseHeight - ($fontSize * $lineHeight - $fontSize) / 2;
	} @else {
		@return $baseHeight - ($lineHeight - $fontSize) / 2;
	}
}


// テキストコンテンツ周辺の空白を、line-heightを計算に入れて算出する。
// 隣接するコンテンツが異なるサイズのテキストである場合。
@function lineGap2($baseHeight, $fontSize, $lineHeight, $fontSize2, $lineHeight2) {
	@if unit($lineHeight) == "" {
		@return $baseHeight - (($fontSize * $lineHeight - $fontSize) / 2) - (($fontSize2 * $lineHeight2 - $fontSize2) / 2);
	} @else {
		@return $baseHeight - ($lineHeight - $fontSize) / 2;
	}
}


// Mixins
// ------------------------------------------------------------------

// -------------------------------------------------------------------------------
// MarginTop
// テキストコンテンツの上マージンを、line-heightを計算に入れて算出する
// -------------------------------------------------------------------------------

// 上の要素と同じテキストサイズ＆line-heightの場合
// --------------------------------------------------
@mixin adjustedMarginTop($margin-top, $font-size, $line-height) {
	margin-top: lineGap($margin-top, $font-size, $line-height);
}

// 上の要素にline-heightがない要素の上マージンを算出
// --------------------------------------------------
@mixin adjustedMarginTopFirst($margin-top, $font-size, $line-height) {
	margin-top: lineGapHalf($margin-top, $font-size, $line-height);
}

// 上の要素と異なるテキストサイズ＆line-heightの場合
// --------------------------------------------------
@mixin adjustedMarginTop($margin-top, $font-size, $line-height, $font-size2, $line-height2) {
	margin-top: lineGap2($margin-top, $font-size, $line-height, $font-size2, $line-height2);
}


// -------------------------------------------------------------------------------
// Padding
// table内やdiv内などのpaddingを、内包するテキストのline-heightを計算に入れて算出する
// -------------------------------------------------------------------------------

// 上下左右のpaddingが同じ値の場合
// --------------------------------------------------
@mixin adjustedPadding($padding, $font-size, $line-height) {
	padding : lineGapHalf($padding, $font-size, $line-height) $padding;
}

// 左右のpaddingが別のサイズの場合
// --------------------------------------------------
@mixin adjustedPaddingTopBtm($padding, $font-size, $line-height, $paddingLR) {
	padding : lineGapHalf($padding, $font-size, $line-height) $paddingLR;
}


// -------------------------------------------------------------------------------
// clearfix
//
// -------------------------------------------------------------------------------
@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}


// -------------------------------------------------------------------------------
// あふれるテキストを ... で省略する
//
// -------------------------------------------------------------------------------
@mixin text-overflow() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}



// -------------------------------------------------------------------------------
// easing
//
// -------------------------------------------------------------------------------
$easeInSine:      cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine:     cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine:   cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInQuad:      cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad:     cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad:   cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInCubic:     cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic:    cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic:  cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInQuart:     cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart:    cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart:  cubic-bezier(0.77, 0, 0.175, 1);
$easeInQuint:     cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint:    cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint:  cubic-bezier(0.86, 0, 0.07, 1);
$easeInExpo:      cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo:     cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo:   cubic-bezier(1, 0, 0, 1);
$easeInCirc:      cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc:     cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc:   cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInBack:      cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack:     cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack:   cubic-bezier(0.68, -0.55, 0.265, 1.55);
