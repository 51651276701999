@charset "UTF-8";

// PageFooter
// ------------------------------------------------------------------
.PageFooter {
	@at-root {

		& {
			padding: 30px 0;

			@include mq(pc) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				width: 1024px;
				margin: 0 auto;
				padding: 30px 0;
			}
		}

		.PageFooter_Logo {
			width: 250px;

			@include mq(sp) {
				display: none;
			}

			img {
				width: 200px;
			}
		}

		.PageFooter_Nav {

			@include mq(sp) {
				padding: 0 15px;
			}

			@include mq(pc) {
				width: 550px;
			}

			ul {
				list-style: none;

				@include mq(pc) {
					display: flex;
				}
			}

			li {

				&:not(:first-of-type) {

					&::before {

						@include mq(pc) {
							content: "";
							display: inline-block;
							width: 2px;
							height: 10px;
							background: $mt-color3;
							position: relative;
							top: 2px;
						}
					}
				}
			}

			a {
				display: inline-block;
				color: $mt-color2;
				font-size: 12px;
				padding: 0 10px;
				transition: all 0.3s ease-in;
				text-decoration: none;

				@include mq(sp) {
					text-decoration: none;
					padding: 10px 0;
					display: block;
				}

				&:hover {

					@include mq(pc) {
						opacity: 0.6;
					}
				}
			}
		}

		.PageFooter_Sns {
			display: flex;
			list-style: none;

			@include mq(sp) {
				margin-top: 32px;
				justify-content: center;
			}

			a {
				display: inline-block;
				width: 40px;
				height: 40px;
				padding-top: 10px;
				border-radius: 40px;
				background: #a694a4;
				text-align: center;
				transition: all 0.3s ease-in;

				&:hover {

					@include mq(pc) {
						opacity: 0.6;
					}
				}
			}

			li {
				vertical-align: top;

				&:not(:first-of-type) {
					margin-left: 20px;
				}

				&:nth-of-type(1) {

					a {
						padding-top: 12px;
					}

					img {
						width: 18px;
					}
				}

				&:nth-of-type(2) {

					img {
						height: 20px;
					}
				}

				&:nth-of-type(3) {

					img {
						width: 20px;
					}
				}
			}
		}

		.PageFooter_Copyright {
			color: $mt-color3;
			text-align: center;

			@include mq(sp) {
				margin-top: 30px;
			}

			@include mq(pc) {
				font-size: 14px;
				width: 100%;
				padding: 60px 0 0;
				// text-align: right;
			}
		}
  }
}
